import CloseIcon from '@mui/icons-material/Close'
import { Button, IconButton, Theme } from '@mui/material'
import { MouseEvent, memo, useCallback } from 'react'
import { colors } from '../themes/Styles'
import { makeStylesFast } from '../themes/fast-styles'
import { SitelineText } from './SitelineText'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    position: 'relative',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
    backgroundColor: colors.orange10,
    minWidth: 300,
    maxWidth: 400,
    padding: theme.spacing(2.5, 2, 2, 2),
    cursor: 'default',
    '& .topBorder': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: theme.spacing(1),
      backgroundColor: colors.orange40,
      borderRadius: theme.spacing(1, 1, 0, 0),
    },
    '& .dismiss': {
      position: 'absolute',
      top: theme.spacing(1.25),
      right: theme.spacing(0.5),
    },
    '& .chip': {
      backgroundColor: colors.orange20,
      color: colors.orange50,
      padding: theme.spacing(0.25, 0.6, 0.25, 0.75),
      borderRadius: 3,
      display: 'inline-block',
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1.5),
      textTransform: 'uppercase',
    },
    '& .heading': {
      margin: theme.spacing(0, 4, 1, 0),
      display: 'flex',
      alignItems: 'center',
      '& .title': {
        display: 'inline-block',
        whiteSpace: 'pre-wrap',
      },
    },
    '& .details': {
      display: 'block',
      whiteSpace: 'pre-wrap',
      marginRight: theme.spacing(2),
    },
    '& .support': {
      display: 'block',
      marginTop: theme.spacing(1),
    },
    '& .button': {
      margin: theme.spacing(0, 0, -1, -1),
      textTransform: 'initial',
      justifyContent: 'flex-start',
    },
  },
}))

interface InfoPopupDetails {
  chipLabel?: string
  title: string
  message: string
  buttonLabel?: string
}

interface SitelineInfoPopupProps {
  details: InfoPopupDetails
  onButtonClick: () => void
  onDismiss: () => void
}

/** Popup with a title, text, and button for announcing changes or info to users */
export const SitelineInfoPopup = memo(function SitelineInfoPopup({
  details,
  onButtonClick,
  onDismiss,
}: SitelineInfoPopupProps) {
  const classes = useStyles()

  const handleClick = useCallback(
    (evt: MouseEvent<HTMLButtonElement>) => {
      evt.stopPropagation()
      onButtonClick()
    },
    [onButtonClick]
  )

  return (
    <div className={classes.root}>
      <div className="topBorder" />
      <div className="dismiss">
        <IconButton
          onClick={(evt) => {
            evt.stopPropagation()
            onDismiss()
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      {details.chipLabel && (
        <SitelineText variant="caption" color="grey90" className="chip">
          {details.chipLabel}
        </SitelineText>
      )}
      <div className="heading">
        <SitelineText variant="body2" color="grey90" bold className="title">
          {details.title}
        </SitelineText>
      </div>
      <SitelineText variant="body2" color="grey90" className="details">
        {details.message}
      </SitelineText>
      {details.buttonLabel && (
        <Button variant="text" className="button" onClick={handleClick}>
          <SitelineText variant="body2" color="blue50" bold>
            {details.buttonLabel}
          </SitelineText>
        </Button>
      )}
    </div>
  )
})
