import { NumericFormat } from 'react-number-format'

export const DollarNumberFormat = ({ value }: { value: number }) => {
  return (
    <NumericFormat
      value={value / 100}
      decimalScale={2}
      fixedDecimalScale
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
    />
  )
}

export const PercentNumberFormat = ({
  value,
  decimalScale = 2,
}: {
  value: number
  decimalScale?: number
}) => {
  return (
    <NumericFormat
      value={value}
      decimalScale={decimalScale}
      fixedDecimalScale
      displayType={'text'}
      suffix={'%'}
    />
  )
}
