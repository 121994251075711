import { FormTemplateStatus, FormTemplateType, SignatureAnnotationType } from '../enums.js'

/** Form template annotation types that require a wet, or physical, signature on the form */
export const PHYSICAL_SIGNATURE_FORM_TEMPLATE_ANNOTATION_TYPES = [
  SignatureAnnotationType.NOTARY,
  SignatureAnnotationType.WET,
  SignatureAnnotationType.WITNESS,
]

export enum ProjectFormsStatus {
  RECEIVED = 'RECEIVED',
  BUILDING = 'BUILDING',
  FINALIZING = 'FINALIZING',
  COMPLETE = 'COMPLETE',
}

export const ORDERED_PROJECT_FORMS_STATUSES = [
  ProjectFormsStatus.RECEIVED,
  ProjectFormsStatus.BUILDING,
  ProjectFormsStatus.FINALIZING,
  ProjectFormsStatus.COMPLETE,
]

export const CANCELED_FORM_TEMPLATE_STATUSES = [
  FormTemplateStatus.CANCELED,
  FormTemplateStatus.DUPLICATE,
]
export const FINALIZING_FORM_TEMPLATE_STATUSES = [
  FormTemplateStatus.READY_FOR_VALIDATION,
  FormTemplateStatus.VALIDATED,
]
export const RECEIVED_FORM_TEMPLATE_STATUSES = [
  FormTemplateStatus.WAITING_ON_ORIGINAL_FILE,
  FormTemplateStatus.PREPARING_FOR_BUILD,
]

export function getSignatureUserVisibleName(type: SignatureAnnotationType): string {
  switch (type) {
    case SignatureAnnotationType.DIGITAL:
      return 'Signature'
    case SignatureAnnotationType.NOTARY:
      return 'Notary Signature'
    case SignatureAnnotationType.WITNESS:
      return 'Witness Signature'
    case SignatureAnnotationType.WET:
      return 'Wet Signature'
  }
}

/**
 * Prettifies a template status enum value.
 * Eg: "READY_FOR_BUILD" => "Ready for build"
 */
export function getTemplateStatusPrettyName(
  status: FormTemplateStatus,
  skippedValidation: boolean
): string {
  if (skippedValidation) {
    return 'Complete (unvalidated)'
  }
  switch (status) {
    case FormTemplateStatus.WAITING_ON_ORIGINAL_FILE:
      return 'Waiting on original file'
    case FormTemplateStatus.PREPARING_FOR_BUILD:
      return 'Preparing for build'
    case FormTemplateStatus.READY_FOR_BUILD:
      return 'Ready for build'
    case FormTemplateStatus.BUILDING:
      return 'Building'
    case FormTemplateStatus.READY_FOR_VALIDATION:
      return 'Ready for validation'
    case FormTemplateStatus.VALIDATED:
      return 'Validated'
    case FormTemplateStatus.COMPLETE:
      return 'Complete'
    case FormTemplateStatus.CANCELED:
      return 'Canceled'
    case FormTemplateStatus.DUPLICATE:
      return 'Duplicate'
  }
}

// This text populates our generated status change messages on the BE and is used to identify
// messages of type status change on the FE
export const FORM_TEMPLATE_STATUS_CHANGE_PREFIX = 'Changed the status from'

// Prefix for form template comment accompanying a skip validation action, used on the frontend
// to identify messages of this type
export const FORM_TEMPLATE_SKIPPED_VALIDATION_PREFIX = '[Skipped validation] '

// Prefix for template comment created with initial form building instructions from the user
export const FORM_TEMPLATE_INSTRUCTIONS_PREFIX = 'Form building instructions'

// The following keys should only be used as default value of a user-entered field.
// The main reason is that they are very likely to be incorrect, changing over time, or empty.
const PAY_APP_RESTRICTED_KEYS = [
  'invoiceNumber',
  'currentDate',
  'currentUser',
  // The signature name and job title are restricted because they will be auto-filled with the
  // current user's info until signed, and would likely be confusing to a user who is unable to
  // edit them if they aren't the signer.
  'signature.name',
  'signature.jobTitle',
  'lienWaiver.exceptions.amounts',
  'lienWaiver.exceptions.dates',
  'lienWaiver.exceptions.signatureDates',
]
const LIEN_WAIVER_RESTRICTED_KEYS = [
  'currentDate',
  'currentUser',
  // The signature name and job title are restricted because they will be auto-filled with the
  // current user's info until signed, and would likely be confusing to a user who is unable to
  // edit them if they aren't the signer.
  'signature.name',
  'signature.jobTitle',
  'sov.contractDate',
  'sov.originalContractSum',
  'sov.changeOrdersTotal',
  'contractSumToDate',
  'currentPaymentDue',
  'currentPaymentDueWrittenForm',
  'previousPayments',
  'totalCompletedToDate',
  'totalRetention',
  'totalRetentionWrittenForm',
  'totalPaidToDate',
  'exceptions.amounts',
  'exceptions.dates',
  'exceptions.signatureDates',
]

/**
 * Determines whether a template variable key is restricted (can only be used on user-entered field).
 * Make sure this is synced with `form-template-annotation.ts`
 */
export function isRestrictedKey(key: string, templateType: FormTemplateType): boolean {
  const isPayApp = [
    FormTemplateType.PAY_APP_LUMP_SUM,
    FormTemplateType.PAY_APP_QUICK,
    FormTemplateType.PAY_APP_UNIT_PRICE,
  ].includes(templateType)
  const restrictedKeys = isPayApp ? PAY_APP_RESTRICTED_KEYS : LIEN_WAIVER_RESTRICTED_KEYS

  // Determine whether the key is restricted by checking for equality, or prefix.
  // For instance it catches `currentUser` and `currentUser.name`.
  const isRestricted = restrictedKeys.some((restrictedKey) => {
    const isExact = key === restrictedKey
    const isPrefix = key.startsWith(`${restrictedKey}.`)
    return isExact || isPrefix
  })

  return isRestricted
}
