import { faFeather, faSignature, faStamp, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Link,
  MenuItem,
  Popper,
  Select,
  TextField,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import { WheelEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { ColorResult, TwitterPicker } from 'react-color'
import {
  FormTemplateAnnotationImageType,
  FormTemplateType,
  SignatureAnnotationType,
  getTemplateVariableValue,
  getTemplateVariablesKeys,
  templateVariablesDocumentation,
} from 'siteline-common-all'
import {
  FormTemplateAnnotationMetadataFieldType,
  TooltipToggleButton,
  fuseSearch,
  getFormTemplateFontFamily,
  makeStylesFast,
  useSitelineSnackbar,
} from 'siteline-common-web'
import {
  FormTemplateAnnotationProperties,
  FormTemplateFont,
  TextAlignment,
  UpdateFormAnnotationInput,
} from '../../../common/graphql/apollo-operations'
import { AnnotationWithOverrides } from '../../../common/util/FormTemplate'

export type UpdateSingleValueFunc = <
  K extends keyof UpdateFormAnnotationInput,
  V extends UpdateFormAnnotationInput[K],
>(
  field: K,
  value: V
) => void

type FieldProps = {
  templateType: FormTemplateType
  annotations: (FormTemplateAnnotationProperties | AnnotationWithOverrides)[]
  onUpdate: (inputs: UpdateFormAnnotationInput[]) => void
  updateSingleValue: UpdateSingleValueFunc
  templateVariables: (typeof templateVariablesDocumentation)[keyof typeof templateVariablesDocumentation]
}

const useStyles = makeStylesFast((theme: Theme) => ({
  fieldGroup: {
    '&:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
}))

export function DynamicTagField({ annotations, updateSingleValue }: FieldProps) {
  if (annotations.length !== 1) {
    return null
  }
  const annotation = annotations[0]
  return (
    <TextField
      label="Dynamic Tag"
      value={annotation.dynamicFieldTag ?? ''}
      onChange={(ev) => {
        const newValue = ev.target.value.length > 0 ? ev.target.value : undefined
        updateSingleValue('dynamicFieldTag', newValue)
      }}
      fullWidth
      required
      variant="outlined"
      size="small"
    />
  )
}

export function UserVisibleNameField({ annotations, updateSingleValue }: FieldProps) {
  const classes = useStyles()
  const initialInput = useMemo(() => {
    if (annotations.length !== 1) {
      return ''
    }
    return annotations[0].userVisibleName
  }, [annotations])
  const [input, setInput] = useState<string>(initialInput)

  // Update the input if it changes from the server
  useEffect(() => {
    setInput(initialInput)
  }, [initialInput])

  // Only make updates (which make API calls) after a second has passed since editing, so we don't
  // send requests for every character change as the user types into the input
  const updateWithDebounce = useMemo(
    () =>
      _.debounce((newInput) => {
        updateSingleValue('userVisibleName', newInput)
      }, 1000),
    [updateSingleValue]
  )

  if (annotations.length !== 1) {
    return null
  }

  return (
    <TextField
      label="User visible name"
      value={input}
      onChange={(e) => {
        setInput(e.target.value)
        if (e.target.value.length) {
          updateWithDebounce(e.target.value)
        }
      }}
      className={classes.fieldGroup}
      fullWidth
      required
      size="small"
      variant="outlined"
    />
  )
}

export function FieldTypeField({ annotations, updateSingleValue }: FieldProps) {
  const classes = useStyles()
  if (annotations.length !== 1) {
    return null
  }
  const annotation = annotations[0]
  return (
    <FormControl size="small" variant="outlined" className={classes.fieldGroup} fullWidth>
      <InputLabel id="annotation-field-type-label">Field type</InputLabel>
      <Select
        labelId="annotation-field-type-label"
        label="Field type"
        value={annotation.fieldType ?? ''}
        onChange={(e) =>
          updateSingleValue(
            'fieldType',
            _.isEmpty(e.target.value)
              ? undefined
              : (e.target.value as FormTemplateAnnotationMetadataFieldType)
          )
        }
        fullWidth
      >
        <MenuItem value="">(default)</MenuItem>
        {_.values(FormTemplateAnnotationMetadataFieldType).map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function ImageTypeField({ annotations, updateSingleValue }: FieldProps) {
  const classes = useStyles()
  if (annotations.length !== 1) {
    return null
  }
  const annotation = annotations[0]
  return (
    <FormControl size="small" variant="outlined" className={classes.fieldGroup} fullWidth>
      <InputLabel id="annotation-field-type-label">Image type</InputLabel>
      <Select
        labelId="annotation-field-type-label"
        label="Image type"
        value={annotation.imageType ?? ''}
        onChange={(e) =>
          updateSingleValue('imageType', e.target.value as FormTemplateAnnotationImageType)
        }
        fullWidth
      >
        {_.values(FormTemplateAnnotationImageType).map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function IsOptionalField({ annotations, updateSingleValue }: FieldProps) {
  const areAllOptional = annotations.every((annotation) => annotation.isOptional)
  const handleUpdate = (checked: boolean) => {
    if (annotations.length > 1) {
      if (
        !window.confirm(
          checked
            ? `Make ${annotations.length} annotations optional?`
            : `Make ${annotations.length} annotations non-optional?`
        )
      ) {
        return
      }
    }
    updateSingleValue('isOptional', checked)
  }
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={areAllOptional}
          value={areAllOptional}
          onChange={(ev, checked) => handleUpdate(checked)}
        />
      }
      label="Optional"
    />
  )
}

export function SignatureTypeField({ annotations, onUpdate, templateType }: FieldProps) {
  const annotation = annotations[0]

  const handleUpdate = useCallback(
    (signatureType: SignatureAnnotationType) => {
      let userVisibleName = ''
      switch (signatureType) {
        case SignatureAnnotationType.DIGITAL:
          userVisibleName = 'Digital signature'
          break
        case SignatureAnnotationType.NOTARY:
          userVisibleName = 'Notary Signature'
          break
        case SignatureAnnotationType.WITNESS:
          userVisibleName = 'Witness Signature'
          break
        case SignatureAnnotationType.WET:
          userVisibleName = 'Wet Signature'
          break
      }

      // When switching to a physical signature, isOptional must become false
      const isOptional = signatureType === SignatureAnnotationType.DIGITAL ? undefined : false

      onUpdate([
        {
          id: annotation.id,
          signatureType,
          userVisibleName,
          isOptional,
        },
      ])
    },
    [annotation.id, onUpdate]
  )

  return (
    <ToggleButtonGroup
      value={annotation.signatureType}
      exclusive
      onChange={(ev, value: SignatureAnnotationType) => handleUpdate(value)}
    >
      <TooltipToggleButton
        value={SignatureAnnotationType.DIGITAL}
        TooltipProps={{ title: 'Digital signature', placement: 'top' }}
      >
        <FontAwesomeIcon fixedWidth icon={faSignature} />
      </TooltipToggleButton>
      <TooltipToggleButton
        value={SignatureAnnotationType.NOTARY}
        disabled={templateType === FormTemplateType.CHANGE_ORDER_REQUEST}
        TooltipProps={{ title: 'Notary signature', placement: 'top' }}
      >
        <FontAwesomeIcon fixedWidth icon={faStamp} />
      </TooltipToggleButton>
      <TooltipToggleButton
        value={SignatureAnnotationType.WITNESS}
        disabled={templateType === FormTemplateType.CHANGE_ORDER_REQUEST}
        TooltipProps={{ title: 'Witness signature', placement: 'top' }}
      >
        <FontAwesomeIcon fixedWidth icon={faUserGroup} />
      </TooltipToggleButton>
      <TooltipToggleButton
        value={SignatureAnnotationType.WET}
        disabled={templateType === FormTemplateType.CHANGE_ORDER_REQUEST}
        TooltipProps={{ title: 'Wet signature', placement: 'top' }}
      >
        <FontAwesomeIcon fixedWidth icon={faFeather} />
      </TooltipToggleButton>
    </ToggleButtonGroup>
  )
}

type TemplateVariableAutocompleteBaseProps = {
  label: string
  templateVariables: (typeof templateVariablesDocumentation)[keyof typeof templateVariablesDocumentation]
  value: string | null
  onChange: (value: string | null) => void
  allowDeprecated: boolean
}

function TemplateVariableAutocompleteBase({
  label,
  templateVariables,
  value,
  onChange,
  allowDeprecated,
}: TemplateVariableAutocompleteBaseProps) {
  const [inputValue, setInputValue] = useState<string>('')
  const snackbar = useSitelineSnackbar()
  const templateVariableOptions = getTemplateVariablesKeys(templateVariables)

  // Allow typing an indexed path that doesn't exist, as long as there is a 0-index that does exist.
  // For instance: changeOrders[5].totalValue should be allowed because changeOrders[0].totalValue exists.
  const isValidPath = templateVariableOptions.includes(inputValue.replace(/\[\d+\]/g, '[0]'))

  // Allow custom contract template variables that start with `contract.*`
  const isValidContractVar = inputValue.match(/^contract\.[\w]+$/)

  const isValid = isValidPath || isValidContractVar
  const availableOptions = [...templateVariableOptions]
  if (isValid && !templateVariableOptions.includes(inputValue)) {
    availableOptions.push(inputValue)
  }

  const onUpdate = (ev: unknown, valuePath: string | null) => {
    if (valuePath === null) {
      onChange(null)
      return
    }

    // Prevent selecting a key that resolves to an object or array.
    // Key must resolve to a string (leaf), or undefined (unknown path)
    const value = getTemplateVariableValue(templateVariables, valuePath)
    if (_.isUndefined(value)) {
      snackbar.showError(`Key ${valuePath} is invalid`)
    }

    onChange(valuePath)
  }

  const isDeprecated = useCallback(
    (key: string) => {
      const documentation = getTemplateVariableValue(templateVariables, key)
      if (_.isUndefined(documentation)) {
        return false
      }
      return documentation.toLowerCase().includes('deprecated')
    },
    [templateVariables]
  )

  return (
    <>
      <Autocomplete
        // Re-render the component whenever the path changes so the autocomplete
        // clears when there's no default value
        key={value}
        size="small"
        options={availableOptions}
        inputValue={inputValue}
        onInputChange={(ev, value) => setInputValue(value)}
        filterOptions={(options, { inputValue }) => fuseSearch(options, inputValue)}
        groupBy={(option: string) => option.split('.').slice(0, -1).join('.')}
        value={value}
        onChange={onUpdate}
        renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
        slots={{
          popper: (props) => (
            <Popper {...props} style={{ minWidth: 'auto' }} placement="bottom-start" />
          ),
        }}
        getOptionDisabled={(key) => (allowDeprecated ? false : isDeprecated(key))}
        getOptionLabel={(key) => {
          const deprecated = isDeprecated(key)
          return deprecated ? `${key} (deprecated)` : key
        }}
      />
      {value && (
        <Typography variant="body2" color="textSecondary">
          {getTemplateVariableValue(templateVariables, value) ?? ''}
        </Typography>
      )}
    </>
  )
}

type TemplateVariableAutocompleteProps = FieldProps & {
  label: string
  field: 'selectedKey' | 'defaultValueKey'
  updateUserVisibleName: boolean
  removeWhenNull: boolean
}

export function TemplateVariableAutocomplete({
  annotations,
  onUpdate,
  label,
  field: field,
  templateVariables,
  updateUserVisibleName,
  removeWhenNull,
}: TemplateVariableAutocompleteProps) {
  if (annotations.length !== 1) {
    return null
  }
  const annotation = annotations[0]
  const selectedPath = field === 'selectedKey' ? annotation.selectedKey : annotation.defaultValueKey

  const onChange = (valuePath: string | null) => {
    if (valuePath === null) {
      if (removeWhenNull) {
        // Special case for removing a default value while doNotRetainOnReset is set to true
        if (field === 'defaultValueKey') {
          onUpdate([{ id: annotation.id, [field]: null, doNotRetainOnReset: false }])
        } else {
          onUpdate([{ id: annotation.id, [field]: null }])
        }
      }
      return
    }

    onUpdate([
      {
        id: annotation.id,
        userVisibleName: updateUserVisibleName ? valuePath : annotation.userVisibleName,
        [field]: valuePath,
      },
    ])
  }

  return (
    <TemplateVariableAutocompleteBase
      value={selectedPath}
      onChange={onChange}
      templateVariables={templateVariables}
      label={label}
      allowDeprecated={false}
    />
  )
}

export function DoNotRetainField({ annotations, updateSingleValue }: FieldProps) {
  const areAllDoNotRetain = annotations.every((annotation) => annotation.doNotRetainOnReset)
  const handleUpdate = (checked: boolean) => {
    if (annotations.length > 1) {
      if (
        !window.confirm(
          checked
            ? `Do not retain on reset for ${annotations.length} annotations?`
            : `Retain on reset for ${annotations.length} annotations?`
        )
      ) {
        return
      }
    }
    updateSingleValue('doNotRetainOnReset', checked)
  }
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={areAllDoNotRetain}
          value={areAllDoNotRetain}
          onChange={(ev, checked) => handleUpdate(checked)}
        />
      }
      label="Do not retain when resetting"
    />
  )
}

export function CopyDefaultValueField({ annotations, updateSingleValue }: FieldProps) {
  const areAllCopyDefault = annotations.every(
    (annotation) => annotation.copyDefaultValueFromPreviousAnnotationValue
  )
  const handleUpdate = (checked: boolean) => {
    if (annotations.length > 1) {
      if (
        !window.confirm(
          checked
            ? `Copy default value from previous for ${annotations.length} annotations?`
            : `Do not copy default value from previous for ${annotations.length} annotations?`
        )
      ) {
        return
      }
    }
    updateSingleValue('copyDefaultValueFromPreviousAnnotationValue', checked)
  }
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={areAllCopyDefault}
          value={areAllCopyDefault}
          onChange={(ev, checked) => handleUpdate(checked)}
        />
      }
      label="Copy value from previous months"
    />
  )
}

export function PrefixField({ annotations, updateSingleValue }: FieldProps) {
  if (annotations.length !== 1) {
    return null
  }
  const annotation = annotations[0]
  return (
    <TextField
      label="Prefix"
      variant="outlined"
      size="small"
      value={annotation.prefix ?? ''}
      onChange={(ev) => updateSingleValue('prefix', ev.target.value || null)}
    />
  )
}

export function SuffixField({ annotations, updateSingleValue }: FieldProps) {
  if (annotations.length !== 1) {
    return null
  }
  const annotation = annotations[0]
  return (
    <TextField
      label="Suffix"
      variant="outlined"
      size="small"
      value={annotation.suffix ?? ''}
      onChange={(ev) => updateSingleValue('suffix', ev.target.value || null)}
    />
  )
}

export function FontFamilyField({ annotations, updateSingleValue }: FieldProps) {
  const classes = useStyles()
  const fontFamilies = annotations.map((annotation) => annotation.fontFamily)
  const uniqueFontFamilies = _.uniq(fontFamilies)
  const selectedFontFamily = uniqueFontFamilies.length === 1 ? uniqueFontFamilies[0] : undefined

  return (
    <FormControl size="small" className={classes.fieldGroup} variant="outlined" fullWidth>
      <InputLabel id="label-font">Font family</InputLabel>
      <Select
        label="Font family"
        labelId="label-font"
        style={selectedFontFamily && getFormTemplateFontFamily(selectedFontFamily)}
        value={selectedFontFamily}
        onChange={(e) => updateSingleValue('fontFamily', e.target.value as FormTemplateFont)}
        required
      >
        {Object.keys(FormTemplateFont).map((font) => (
          <MenuItem
            key={font}
            value={font}
            style={getFormTemplateFontFamily(font as FormTemplateFont)}
          >
            {font}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function TextAlignmentField({ annotations, updateSingleValue }: FieldProps) {
  const alignments = annotations.map((annotation) => annotation.textAlignment)
  const uniqueAlignments = _.uniq(alignments)
  const selectedAlignment = uniqueAlignments.length === 1 ? uniqueAlignments[0] : undefined

  return (
    <ToggleButtonGroup
      value={selectedAlignment}
      exclusive
      size="small"
      // According to the types documentation, when `exclusive` is true this is a single value. If
      // no value is selected and `exclusive` is true, the value is null.
      onChange={(ev, value: TextAlignment | null) => {
        if (!value) {
          return
        }
        updateSingleValue('textAlignment', value)
      }}
    >
      <ToggleButton value={TextAlignment.LEFT}>
        <FormatAlignLeftIcon fontSize="small" />
      </ToggleButton>
      <ToggleButton value={TextAlignment.CENTER}>
        <FormatAlignCenterIcon fontSize="small" />
      </ToggleButton>
      <ToggleButton value={TextAlignment.RIGHT}>
        <FormatAlignRightIcon fontSize="small" />
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export function WrapTextField({ annotations, updateSingleValue }: FieldProps) {
  const wraps = annotations.map((annotation) => annotation.wrapText)
  const uniqueWraps = _.uniq(wraps)
  const wrapText = uniqueWraps.length === 1 ? uniqueWraps[0] : undefined
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={wrapText ?? false}
          indeterminate={uniqueWraps.length > 1}
          onChange={(ev, checked) => {
            updateSingleValue('wrapText', checked)
          }}
        />
      }
      label="Wrap text if too long"
    />
  )
}

type GeometryFieldProps = FieldProps & {
  field: 'xStart' | 'yStart' | 'width' | 'height'
  label: string
}

export function GeometryField({
  annotations,
  updateSingleValue,
  label,
  field,
}: GeometryFieldProps) {
  const uniqueAlignments = _.uniq(annotations.map((annotation) => annotation[field]))
  const value = uniqueAlignments.length === 1 ? uniqueAlignments[0] : ''

  return (
    <TextField
      label={label}
      value={value}
      type="number"
      slotProps={{
        htmlInput: {
          onWheel: (e: WheelEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            e.currentTarget.blur(),
        },
      }}
      onChange={(e) => updateSingleValue(field, Number(e.target.value))}
      fullWidth
      required
      variant="outlined"
      size="small"
    />
  )
}

export function ColorField({ annotations, updateSingleValue }: FieldProps) {
  const colors = annotations.map((annotation) => annotation.fontColor)
  const uniqueColors = _.uniq(colors)
  const selectedColor = uniqueColors.length === 1 ? uniqueColors[0] : undefined

  const onColorPickerChange = (color: ColorResult) => {
    const colorWithoutHash = color.hex.replace('#', '')
    updateSingleValue('fontColor', colorWithoutHash)
  }
  return (
    <TwitterPicker
      colors={['#000000', '#ff0000', '#0000ff', '#008800']}
      color={selectedColor && `#${selectedColor}`}
      onChangeComplete={onColorPickerChange}
      width="300"
      triangle="hide"
      styles={{
        default: {
          card: { boxShadow: 'none' },
          body: { padding: 0 },
        },
      }}
    />
  )
}

export function SyncTagField({ annotations, updateSingleValue }: FieldProps) {
  if (annotations.length !== 1) {
    return null
  }
  const annotation = annotations[0]
  return (
    <TextField
      label="Sync tag"
      value={annotation.syncTag ?? ''}
      onChange={(ev) => {
        const newValue = ev.target.value.length > 0 ? ev.target.value : null
        updateSingleValue('syncTag', newValue)
      }}
      fullWidth
      required
      variant="outlined"
      size="small"
      helperText={
        <>
          As the user types into another annotation that has the same tag, text is copied to this
          annotation.{' '}
          <Link
            href="https://www.loom.com/share/aa6e23f565614a12bd21f0e905e7cb6f"
            target="__blank"
            underline="hover"
          >
            More info.
          </Link>
        </>
      }
    />
  )
}
