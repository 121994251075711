import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { IconButton, Typography } from '@mui/material'

type PdfPageSelectorProps = {
  pageCount: number
  pageNumber: number
  setPageNumber: (pageNumber: number) => void
}

export function PdfPageSelector({ pageCount, pageNumber, setPageNumber }: PdfPageSelectorProps) {
  const goToPreviousPage = () => {
    if (pageNumber === 1) {
      return
    }
    setPageNumber(pageNumber - 1)
  }

  const goToNextPage = () => {
    if (pageNumber >= pageCount) {
      return
    }
    setPageNumber(pageNumber + 1)
  }

  return (
    <>
      <IconButton size="small" onClick={() => goToPreviousPage()} disabled={pageNumber === 1}>
        <KeyboardArrowLeftIcon />
      </IconButton>
      <Typography variant="subtitle2" display="inline">
        Page {pageNumber} / {pageCount}
      </Typography>
      <IconButton size="small" onClick={() => goToNextPage()} disabled={pageNumber === pageCount}>
        <KeyboardArrowRightIcon />
      </IconButton>
    </>
  )
}
