import moment from 'moment-timezone'

/**
 * In certain scenarios, we don't need the timezone accounted for when getting the date.
 * NOTE: be cautious when using this to prevent off by 1 date errors (i.e. #1420)
 */
export function getTimeNoTimeZone(date?: string): moment.Moment {
  // eslint-disable-next-line momentjs/no-moment-constructor
  return moment(date)
}
