import _ from 'lodash'
import {
  PayAppRequirementCondition,
  TemplateVariantForAutocompleteQuery,
  UpdatePayAppRequirementGroupInput,
  UpdatePayAppRequirementInput,
} from '../graphql/apollo-operations'

/**
 * Note: This matches the equivalent function in common/src/utils/pay-app-requirements.ts
 * Given an array of conditions, check whether any conflicting conditions are provided.
 */
function findInvalidConditions(
  conditions: PayAppRequirementCondition[]
): PayAppRequirementCondition[] | undefined {
  const invalidConditionCombinations = [
    // A pay app cannot both be progress only and retention only
    [PayAppRequirementCondition.PROGRESS_ONLY, PayAppRequirementCondition.RETENTION_ONLY],
    // A pay app cannot both have no progress remaining and some progress remaining
    [
      PayAppRequirementCondition.NO_PROGRESS_REMAINING,
      PayAppRequirementCondition.SOME_PROGRESS_REMAINING,
    ],
    // A pay app cannot both have no retention held and some retention held
    [PayAppRequirementCondition.NO_RETENTION_HELD, PayAppRequirementCondition.SOME_RETENTION_HELD],
  ]

  const invalidConditionCombination = invalidConditionCombinations.find((invalidCombination) =>
    invalidCombination.every((condition) => conditions.includes(condition))
  )

  return invalidConditionCombination
}

// Finds an earlier pay app requirement that has a strict subset of the conditions.
// ex: "if (a) {...} else if (a && b) {...}"" means that the else if won't ever be hit
function hasSupersetConditions(
  currentRequirement: UpdatePayAppRequirementInput,
  previousRequirements: UpdatePayAppRequirementInput[]
): UpdatePayAppRequirementInput | undefined {
  if (currentRequirement.conditions.length === 0) {
    // The fallback requirement is always valid
    return
  }

  return previousRequirements.find((earlierRequirement) => {
    return _.difference(earlierRequirement.conditions, currentRequirement.conditions).length === 0
  })
}

export const USER_FRIENDLY_CONDITION_NAMES = {
  [PayAppRequirementCondition.PROGRESS_ONLY]: 'On progress pay apps',
  [PayAppRequirementCondition.RETENTION_ONLY]: 'On retention pay apps',
  [PayAppRequirementCondition.NO_PROGRESS_REMAINING]: 'Progress 100% billed',
  [PayAppRequirementCondition.SOME_PROGRESS_REMAINING]: 'Progress NOT 100% billed',
  [PayAppRequirementCondition.NO_RETENTION_HELD]: '$0 retention held',
  [PayAppRequirementCondition.SOME_RETENTION_HELD]: '>$0 Retention held',
  [PayAppRequirementCondition.STORED_MATERIALS_BILLED]: 'Stored materials billed',
}

// Requirement input with a key that allows stable re-renders.
// Key is set to requirement ID, or uuidv4() in case of a new requirement
export type PayAppRequirementWithKey = UpdatePayAppRequirementInput & {
  key: string
}

// Group input with a key that allows stable re-renders.
// Key is set to group ID, or uuidv4() in case of a new group
export type PayAppRequirementGroupWithKey = Omit<
  UpdatePayAppRequirementGroupInput,
  'requirements'
> & {
  key: string
  requirements: PayAppRequirementWithKey[]
}

type VariantForPayAppRequirementGroupValidation =
  TemplateVariantForAutocompleteQuery['formTemplateVariant']

export async function validatePayAppRequirementGroups(
  payAppRequirementGroups: PayAppRequirementGroupWithKey[],
  getFormTemplateVariant: (
    id: string
  ) => Promise<VariantForPayAppRequirementGroupValidation | undefined>
) {
  const getVariantName = async (id: string | null) => {
    if (!id) {
      return ''
    }
    const template = await getFormTemplateVariant(id)
    return template?.userVisibleName ?? ''
  }

  for (const group of payAppRequirementGroups) {
    const sortedRequirements = _.sortBy(group.requirements, (requirement) => requirement.groupOrder)

    // Find if any pay app requirement other than the failback has no conditions. If so, fail.
    const missingConditions = sortedRequirements.find((requirement, index) => {
      if (index === sortedRequirements.length - 1) {
        return false
      }
      return requirement.conditions.length === 0
    })
    if (missingConditions) {
      const templateName = await getVariantName(missingConditions.templateVariantId ?? null)
      throw new Error(`${templateName} must have conditions since it is not the fallback.`)
    }

    // Find if an earlier pay app requirement has a strict subset of conditions. If so, fail.
    for (const requirement of sortedRequirements) {
      const index = _.indexOf(sortedRequirements, requirement)
      const supersetConditions = hasSupersetConditions(
        requirement,
        sortedRequirements.slice(0, index)
      )
      if (!supersetConditions) {
        continue
      }
      const supersetTemplateName = await getVariantName(
        supersetConditions.templateVariantId ?? null
      )
      const templateName = await getVariantName(requirement.templateVariantId ?? null)

      throw new Error(
        `${templateName} cannot have a superset of ${supersetTemplateName}'s conditions since it is later in the list.`
      )
    }

    // Find if any pay app requirement has conflicting conditions. If so, fail.
    for (const requirement of sortedRequirements) {
      const invalidConditions = findInvalidConditions([...requirement.conditions])
      if (!invalidConditions) {
        continue
      }

      const templateName = await getVariantName(requirement.templateVariantId ?? null)
      const names = invalidConditions
        .map((condition) => USER_FRIENDLY_CONDITION_NAMES[condition])
        .join(', ')

      throw new Error(`${templateName} has incompatible conditions ${names}`)
    }
  }
}
