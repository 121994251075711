import AddIcon from '@mui/icons-material/Add'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material'
import _ from 'lodash'
import { useCallback } from 'react'
import { colors, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { v4 as uuidv4 } from 'uuid'
import {
  BillingType,
  FormTemplateType,
  useTemplateVariantForAutocompleteLazyQuery,
} from '../../common/graphql/apollo-operations'
import {
  PayAppRequirementGroupWithKey,
  validatePayAppRequirementGroups,
} from '../../common/util/PayAppRequirements'
import { PayAppRequirementGroup } from './PayAppRequirementGroup'

const useStyles = makeStylesFast(() => ({
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
    '& .MuiTableCell-root': {
      borderBottom: 0,
    },
    '& .MuiTableHead-root': {
      borderBottom: `1px solid ${colors.grey30}`,
    },
    '& .MuiTableBody-root': {
      borderBottom: `1px solid ${colors.grey30}`,
    },
  },
  footerRow: {
    border: 'none',
  },
}))

interface PayAppRequirementGroupsProps {
  billingType: BillingType
  usesManualStoredMaterials: boolean
  payAppRequirementGroups: PayAppRequirementGroupWithKey[]
  setPayAppRequirementGroups: (newGroups: PayAppRequirementGroupWithKey[]) => void
  isEditing: boolean
  /** Pass in undefined if saving is handled on a parent component */
  onSave?: () => Promise<void>
  /** Pass in undefined if saving is handled on a parent component */
  onCancel?: () => void
  title?: string
  elevation?: number
}

export default function PayAppRequirementGroups({
  billingType,
  usesManualStoredMaterials,
  payAppRequirementGroups,
  setPayAppRequirementGroups,
  isEditing,
  onSave,
  onCancel,
  elevation,
  title = 'Pay App Requirement Groups',
}: PayAppRequirementGroupsProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const [getVariant] = useTemplateVariantForAutocompleteLazyQuery()

  // Determine template type to show
  let templateType: FormTemplateType
  switch (billingType) {
    case BillingType.UNIT_PRICE:
      templateType = FormTemplateType.PAY_APP_UNIT_PRICE
      break
    case BillingType.LUMP_SUM:
      templateType = FormTemplateType.PAY_APP_LUMP_SUM
      break
    case BillingType.TIME_AND_MATERIALS:
      templateType = FormTemplateType.PAY_APP_TIME_AND_MATERIALS
      break
    case BillingType.QUICK:
      templateType = FormTemplateType.PAY_APP_QUICK
      break
  }

  const updateGroup = (group: PayAppRequirementGroupWithKey) => {
    const index = _.findIndex(payAppRequirementGroups, (g) => g.key === group.key)
    if (index === -1) {
      throw new Error('Could not find group to update')
    }
    const newGroups = [...payAppRequirementGroups]
    newGroups[index] = group
    setPayAppRequirementGroups(newGroups)
  }

  const removeGroup = (group: PayAppRequirementGroupWithKey) => {
    setPayAppRequirementGroups(_.without(payAppRequirementGroups, group))
  }

  const addGroup = () => {
    setPayAppRequirementGroups([
      ...payAppRequirementGroups,
      {
        key: uuidv4(),
        order: payAppRequirementGroups.length + 1,
        requirements: [
          {
            key: uuidv4(),
            templateVariantId: null,
            groupOrder: 1,
            conditions: [],
          },
        ],
      },
    ])
  }

  const getFormTemplateVariant = useCallback(
    async (id: string) => {
      const template = await getVariant({
        variables: { id },
      })
      return template.data?.formTemplateVariant
    },
    [getVariant]
  )

  const handleSave = useCallback(async () => {
    if (!onSave) {
      return
    }
    // Check that all the pay app requirement groups are valid
    try {
      await validatePayAppRequirementGroups(payAppRequirementGroups, getFormTemplateVariant)
      await onSave()
    } catch (error) {
      snackbar.showError(error.message)
      return
    }
  }, [getFormTemplateVariant, onSave, payAppRequirementGroups, snackbar])

  return (
    <Card elevation={elevation}>
      <CardHeader
        title={title}
        action={
          onCancel ? (
            <Button color="primary" onClick={onCancel}>
              {isEditing ? 'Cancel' : 'Edit'}
            </Button>
          ) : undefined
        }
      />
      <CardContent className={classes.cardContent}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Group</TableCell>
              <TableCell style={{ width: '10%' }}>Order</TableCell>
              <TableCell style={{ width: '30%' }}>Conditions</TableCell>
              <TableCell>Template variant</TableCell>
              <TableCell>Template Status</TableCell>
              <TableCell style={{ width: 135 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          {payAppRequirementGroups.map((requirementGroup, index) => (
            <PayAppRequirementGroup
              key={requirementGroup.key}
              payAppRequirementGroup={requirementGroup}
              setPayAppRequirementGroup={updateGroup}
              removePayAppRequirementGroup={() => removeGroup(requirementGroup)}
              isEditing={isEditing}
              templateType={templateType}
              groupIndex={index}
              usesManualStoredMaterials={usesManualStoredMaterials}
            />
          ))}
          {isEditing && (
            <TableFooter>
              <TableRow>
                <TableCell></TableCell>
                <TableCell colSpan={2} className={classes.footerRow}>
                  <Button startIcon={<AddIcon />} size="small" onClick={() => addGroup()}>
                    Add Pay App Requirement Group
                  </Button>
                </TableCell>
                <TableCell colSpan={1}></TableCell>
                <TableCell align="right">
                  {onSave && (
                    <Button color="primary" variant="contained" onClick={handleSave}>
                      Save
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </CardContent>
    </Card>
  )
}
