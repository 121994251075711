import _ from 'lodash'
import { PDFDocument } from 'pdf-lib'
import { pMap } from 'siteline-common-all'
import { ProjectOnboardingFormType, colors } from 'siteline-common-web'
import type { Tagged } from 'type-fest'
import {
  AnnotationOverrideProperties,
  FormTemplateAnnotationProperties,
  FormTemplateProperties,
  FormTemplateStatus,
  FormTemplateTag,
  FormTemplateType,
  FormTemplateVersionProperties,
  PayAppRequirementGroupProperties,
  StoredFileProperties,
  SubcontractorLienWaiverTemplateSetProperties,
} from '../graphql/apollo-operations'

export function getLatestTemplateVersion(
  formTemplate: FormTemplateProperties
): FormTemplateVersionProperties | null {
  const latest = _.last(_.orderBy(formTemplate.versions, (version) => version.versionNumber, 'asc'))
  return latest ?? null
}

type MinimalFormTemplateWithVersions = {
  readonly versions: readonly {
    readonly __typename: 'FormTemplateVersion'
    readonly versionNumber: number
    readonly isReadyForManualStoredMaterials: boolean
  }[]
}

export function getIsLatestTemplateVersionReadyForManualStoredMaterials(
  formTemplate: MinimalFormTemplateWithVersions
): boolean {
  const latest = _.last(_.orderBy(formTemplate.versions, (version) => version.versionNumber, 'asc'))
  return latest?.isReadyForManualStoredMaterials ?? true
}

function prettyNameFromUpperSnakeCase(str: string): string {
  return _.chain(str).replace(/_/g, ' ').toLower().upperFirst().value()
}

/**
 * Prettifies a template tag enum value.
 * Eg: "CONDITIONAL_PROGRESS" => "Conditional progress"
 */
export function getTemplateTagPrettyName(tag: FormTemplateTag | FormTemplateType): string {
  return prettyNameFromUpperSnakeCase(tag)
}

/**
 * Returns the color of a template status.
 */
export function getTemplateStatusColor(
  status: FormTemplateStatus,
  skippedValidation: boolean
): {
  color: string
  backgroundColor: string
} {
  if (skippedValidation) {
    // A template that has skipped validation is considered complete, but should be flagged
    // as unvalidated
    return { backgroundColor: colors.green10, color: colors.red50 }
  }
  switch (status) {
    case FormTemplateStatus.WAITING_ON_ORIGINAL_FILE:
    case FormTemplateStatus.PREPARING_FOR_BUILD:
    case FormTemplateStatus.READY_FOR_BUILD:
      return { backgroundColor: colors.grey10, color: colors.grey50 }
    case FormTemplateStatus.BUILDING:
      return { backgroundColor: colors.yellow20, color: colors.yellow50 }
    case FormTemplateStatus.READY_FOR_VALIDATION:
      return { backgroundColor: colors.blue10, color: colors.blue50 }
    case FormTemplateStatus.VALIDATED:
      return { backgroundColor: colors.purple10, color: colors.purple50 }
    case FormTemplateStatus.COMPLETE:
      return { backgroundColor: colors.green10, color: colors.green50 }
    case FormTemplateStatus.CANCELED:
      return { backgroundColor: colors.red10, color: colors.red50 }
    case FormTemplateStatus.DUPLICATE:
      return { backgroundColor: colors.red10, color: colors.red50 }
  }
}

export function readableProjectOnboardingFormType(formType: ProjectOnboardingFormType) {
  switch (formType) {
    case ProjectOnboardingFormType.PAY_APP:
      return 'Pay app'
    case ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER:
      return 'Primary lien waiver'
    case ProjectOnboardingFormType.VENDOR_LIEN_WAIVER:
      return 'Vendor lien waiver'
    case ProjectOnboardingFormType.CHANGE_ORDER_REQUEST:
      return 'Change order request'
    case ProjectOnboardingFormType.CHANGE_ORDER_LOG:
      return 'Change order log'
    case ProjectOnboardingFormType.COMPLIANCE:
      return 'Compliance'
  }
}

export function readableFormTemplateType(formTemplateType: FormTemplateType) {
  switch (formTemplateType) {
    case FormTemplateType.PAY_APP_LUMP_SUM:
      return 'Lump sum pay app'
    case FormTemplateType.PAY_APP_UNIT_PRICE:
      return 'Unit price pay app'
    case FormTemplateType.PAY_APP_TIME_AND_MATERIALS:
      return 'T&M pay app'
    case FormTemplateType.PAY_APP_QUICK:
      return 'Quick bill pay app'
    case FormTemplateType.LIEN_WAIVER:
      return 'Lien waiver'
    case FormTemplateType.CHANGE_ORDER_REQUEST:
      return 'COR'
    case FormTemplateType.CHANGE_ORDER_LOG:
      return 'Change order log'
    case FormTemplateType.LEGAL_DOCUMENT:
      return 'Compliance'
  }
}

export const finishedFormTemplateStatus = [
  FormTemplateStatus.CANCELED,
  FormTemplateStatus.COMPLETE,
  FormTemplateStatus.DUPLICATE,
]

export const payAppFormTemplateTypes = [
  FormTemplateType.PAY_APP_LUMP_SUM,
  FormTemplateType.PAY_APP_UNIT_PRICE,
  FormTemplateType.PAY_APP_QUICK,
]

export type PageRange = [number, number]
interface PdfTemplate {
  name: string
  pageRange: PageRange
}

/** Copies pages from a PDF into multiple new PDFs with the given page ranges */
export async function copyPdfPagesToPdfs(fromPdf: StoredFileProperties, toPdfs: PdfTemplate[]) {
  const fromPdfBytes = await fetch(fromPdf.url).then((res) => res.arrayBuffer())
  const fromDocument = await PDFDocument.load(fromPdfBytes)

  return pMap(toPdfs, async (toPdf) => {
    const toPdfDoc = await PDFDocument.create()
    const {
      pageRange: [fromPage, toPage],
      name,
    } = toPdf
    const copiedPages = await toPdfDoc.copyPages(fromDocument, _.range(fromPage - 1, toPage))
    copiedPages.forEach((page) => toPdfDoc.addPage(page))

    const toPdfBytes = await toPdfDoc.save()
    const toBlob = new Blob([toPdfBytes], { type: 'application/pdf' })
    const toFile = new File([toBlob], name)
    return toFile
  })
}

export type AnnotationWithOverrides = Tagged<
  Pick<
    FormTemplateAnnotationProperties,
    | 'id'
    | 'permanentId'
    | 'type'
    | 'width'
    | 'height'
    | 'xStart'
    | 'yStart'
    | 'pageNumber'
    | 'syncTag'

    // Overridable
    | 'copyDefaultValueFromPreviousAnnotationValue'
    | 'defaultValueKey'
    | 'doNotRetainOnReset'
    | 'dynamicFieldTag'
    | 'fieldType'
    | 'fontColor'
    | 'fontFamily'
    | 'imageType'
    | 'isOptional'
    | 'prefix'
    | 'suffix'
    | 'selectedKey'
    | 'textAlignment'
    | 'userVisibleName'
    | 'wrapText'
    | 'signatureType'
  >,
  'AnnotationWithOverrides'
>
export function applyOverride(
  annotation: FormTemplateAnnotationProperties,
  override: AnnotationOverrideProperties
): AnnotationWithOverrides {
  return {
    id: annotation.id,
    pageNumber: annotation.pageNumber,
    permanentId: annotation.permanentId,
    dynamicFieldTag: annotation.dynamicFieldTag,
    type: annotation.type,
    width: annotation.width,
    height: annotation.height,
    xStart: annotation.xStart,
    yStart: annotation.yStart,

    // Overridable fields
    copyDefaultValueFromPreviousAnnotationValue:
      override.copyDefaultValueFromPreviousAnnotationValue ??
      annotation.copyDefaultValueFromPreviousAnnotationValue,
    defaultValueKey: override.defaultValueKey ?? annotation.defaultValueKey,
    doNotRetainOnReset: override.doNotRetainOnReset ?? annotation.doNotRetainOnReset,
    signatureType: override.signatureType ?? annotation.signatureType,
    fieldType: override.fieldType ?? annotation.fieldType,
    fontColor: override.fontColor ?? annotation.fontColor,
    fontFamily: override.fontFamily ?? annotation.fontFamily,
    imageType: override.imageType ?? annotation.imageType,
    isOptional: override.isOptional ?? annotation.isOptional,
    prefix: override.prefix ?? annotation.prefix,
    selectedKey: override.selectedKey ?? annotation.selectedKey,
    suffix: override.suffix ?? annotation.suffix,
    textAlignment: override.textAlignment ?? annotation.textAlignment,
    userVisibleName: override.userVisibleName ?? annotation.userVisibleName,
    wrapText: override.wrapText ?? annotation.wrapText,
    syncTag: override.syncTag ?? annotation.syncTag,
  } as unknown as AnnotationWithOverrides
}

export function arePayAppFormsProcessing(
  payAppRequirementGroups: PayAppRequirementGroupProperties[]
): boolean {
  const requirements = payAppRequirementGroups.flatMap((group) => group.payAppRequirements)
  return requirements.some(
    (requirement) => requirement.templateVariant?.template.isCustomerReady === false
  )
}

export function areLienWaiverFormsProcessing(
  lienWaivers: SubcontractorLienWaiverTemplateSetProperties
) {
  return [
    lienWaivers.conditionalProgressVariant?.template.isCustomerReady,
    lienWaivers.conditionalFinalVariant?.template.isCustomerReady,
    lienWaivers.unconditionalProgressVariant?.template.isCustomerReady,
    lienWaivers.unconditionalFinalVariant?.template.isCustomerReady,
  ].includes(false)
}
