import { gql } from '@apollo/client'
import SearchIcon from '@mui/icons-material/Search'
import { Container, Grid2, InputAdornment, TextField, Theme } from '@mui/material'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { useCallback, useEffect, useState } from 'react'
import { makeStylesFast, useDebouncedSearch } from 'siteline-common-web'
import { Temporal } from 'temporal-polyfill'
import type { JsonObject } from 'type-fest'
import { useNavBar } from '../../common/components/NavBar'
import Page from '../../common/components/Page'
import { API_ENV } from '../../common/config/constants'
import JobList from './JobList'
import TaskDetails from './TaskDetails'
import TaskList from './TaskList'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  card: {
    marginTop: theme.spacing(4),
  },
}))

gql`
  query cloudLoggingLogs($input: CloudLoggingLogsInput!) {
    cloudLoggingLogs(input: $input) {
      entries
      nextPageToken
    }
  }
`

/**
 * A timestamp from the cloud logging API.
 */
type Timestamp = {
  seconds: number
  nanos: number
}

/**
 * A single log entry from the cloud logging API.
 */
export type LogEntry = {
  timestamp: Timestamp
  resource: {
    labels: Partial<Record<string, string>>
    type: string
  }
  severity: string
  logName: string
  trace: string
  receiveTimestamp: Timestamp
  spanId: string
  traceSampled: false
  message: JsonObject
  'logging.googleapis.com/trace'?: string
  'logging.googleapis.com/insertId': string
  httpRequest: {
    requestMethod: string
    requestUrl: string
    status: number
  } | null
}

/**
 * A single cron job execution.
 */
export type Job = {
  id: string
  name: string
  startLog: LogEntry
  endLog: LogEntry | null
}

/**
 * A task created by a cron job.
 * For instance a `collectionsNotifications` task that processes `notificationId: ac917a0a-a10d-4d2b-bce5-92ed56efebef`
 * will have the task ID: `<unique id>_notificationId-ac917a0a-a10d-4d2b-bce5-92ed56efebef
 */
export type Task = {
  id: string
  prefix: string
  metadata: { [key: string]: string }
  startLog: LogEntry
  endLog: LogEntry | null
  jobName: string
}

/**
 * A single log line from a task execution
 */
export type TaskLog = {
  log: LogEntry
}

/**
 * Converts a log timestamp to a moment object.
 */
export function timestampToMoment(timestamp: Timestamp): Moment {
  return moment
    .tz(timestamp.seconds * 1000, 'America/Los_Angeles')
    .set('milliseconds', Math.round(timestamp.nanos / 1_000_000))
}

/**
 * Converts a log timestamp to an ISO string with nanosecond precision.
 * Note that moment doesn't support nanosecond precision, so we need to use Temporal.
 */
function timestampToIso(timestamp: Timestamp): string {
  return Temporal.Instant.fromEpochSeconds(timestamp.seconds)
    .add({ nanoseconds: timestamp.nanos })
    .toString()
}

/**
 * Opens logs for a given filter, startTime, and pinnedLog.
 * Goes through the account chooser so that users can select their company gsuite account.
 */
export function openLogs({ filter, pinnedLog }: { filter: string; pinnedLog?: LogEntry }): void {
  const project = API_ENV === 'production' ? 'siteline-prod' : 'siteline-staging'
  const logUrl = new URL('https://console.cloud.google.com')
  logUrl.searchParams.set('project', project)

  const pathParamsUnescaped: { [key: string]: string }[] = []
  pathParamsUnescaped.push({ query: filter })
  if (pinnedLog) {
    const timestamp = timestampToMoment(pinnedLog.timestamp)
    const iso = timestampToIso(pinnedLog.timestamp)
    const pinnedLogId = `${iso}/${pinnedLog['logging.googleapis.com/insertId']}`
    pathParamsUnescaped.push({ pinnedLogId })
    pathParamsUnescaped.push({ aroundTime: timestamp.toISOString() })
    pathParamsUnescaped.push({ duration: 'PT1H' })
  }

  const escapedParams = pathParamsUnescaped
    .map((param) => {
      return Object.entries(param)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join(';')
    })
    .join(';')
  logUrl.pathname = `/logs/query;${escapedParams}`

  const accountChooserUrl = new URL('https://accounts.google.com/AccountChooser')
  accountChooserUrl.searchParams.set('continue', logUrl.toString())

  // https://console.cloud.google.com/logs/query;query=<query>;cursorTimestamp=<startTime>;duration=PT1H?project=siteline-prod
  // via account chooser https://accounts.google.com/AccountChooser?continue=<logUrl>
  window.open(accountChooserUrl.toString(), '_blank')
}

/**
 * Returns the message from a log entry
 */
export function getMessageFromLogEntry(logEntry: LogEntry): string | null {
  if (!_.isString(logEntry.message.message)) {
    return null
  }
  return logEntry.message.message
}

/**
 * Page for exploring cron jobs and their tasks + logs.
 */
export default function JobsPage() {
  const classes = useStyles()
  const [job, setJob] = useState<Job | null>(null)
  const [task, setTask] = useState<Task | null>(null)
  const { setIsOpen: setNavBarOpen } = useNavBar()
  const { debouncedSearch, search, onSearch } = useDebouncedSearch()
  const [didInteract, setDidInteract] = useState(false)

  useEffect(() => {
    setNavBarOpen(!didInteract)
  }, [didInteract, setNavBarOpen])

  const handleJobSelected = useCallback(
    (job: Job) => {
      setDidInteract(true)
      setJob(job)
      setTask(null)
      onSearch('')
    },
    [onSearch]
  )

  const handleTaskSelected = useCallback((task: Task) => {
    setTask(task)
  }, [])

  useEffect(() => {
    if (search.length > 0) {
      setDidInteract(true)
      setJob(null)
      setTask(null)
    }
  }, [search.length, setJob, setTask])

  return (
    <Page className={classes.root} title="Jobs">
      <Container maxWidth={false}>
        <Grid2 container spacing={2}>
          <Grid2 size={4}>
            <TextField
              fullWidth
              size="small"
              value={search}
              onChange={(event) => onSearch(event.target.value)}
              placeholder="Search tasks by ID or metadata"
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
              sx={{ marginBottom: 2 }}
            />
            <JobList job={job} onJobSelected={handleJobSelected} />
          </Grid2>
          <Grid2 size={8}>
            <TaskList
              job={job}
              task={task}
              search={debouncedSearch}
              onTaskSelected={handleTaskSelected}
            />
          </Grid2>
          {task && (
            <Grid2 size={{ xs: 12 }}>
              <TaskDetails job={job} task={task} />
            </Grid2>
          )}
        </Grid2>
      </Container>
    </Page>
  )
}
