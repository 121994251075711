import { gql } from '@apollo/client'
import {
  Assignment,
  CloudDownload,
  CloudUpload,
  ContentCopy,
  DeleteForever,
  Download,
  Upload,
} from '@mui/icons-material'
import SettingsIcon from '@mui/icons-material/Settings'
import {
  Divider,
  FormControl,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
} from '@mui/material'
import clsx from 'clsx'
import copy from 'copy-to-clipboard'
import saveAs from 'file-saver'
import _ from 'lodash'
import { bindMenu, bindToggle, usePopupState } from 'material-ui-popup-state/hooks'
import { useCallback, useMemo, useState } from 'react'
import 'react-resizable/css/styles.css'
import { getFilename } from 'siteline-common-all'
import {
  FormTemplate,
  makeStylesFast,
  saveUrlAs,
  toReferences,
  useSitelineSnackbar,
} from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import { useFilePicker } from 'use-file-picker'
import * as fragments from '../../../common/graphql/Fragments'
import {
  FormTemplateProperties,
  useCloneFormTemplateVersionMutation,
  useDeleteFormTemplateVersionMutation,
  useUpdateFormTemplateVersionMutation,
} from '../../../common/graphql/apollo-operations'
import { FormTemplateVersionAnnotationImport } from '../form-template-version-details/FormTemplateVersionAnnotationImport'

const useStyles = makeStylesFast((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  more: {
    marginLeft: theme.spacing(1),
  },
  switch: {
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
    },
  },
}))

gql`
  mutation updateFormTemplateVersion($input: UpdateFormTemplateVersionInput!) {
    updateFormTemplateVersion(input: $input) {
      ...FormTemplateVersionProperties
    }
  }
  ${fragments.formTemplateVersion}
`

gql`
  mutation deleteFormTemplateVersion($id: ID!) {
    deleteFormTemplateVersion(id: $id) {
      id
    }
  }
`

type TemplateVersionSelectorProps = {
  template: FormTemplateProperties
  versionId: string
  onVersionIdChange: (versionId: string) => void
  className?: string
}

/**
 * Template version selector which shows in the template navigation breadcrumbs.
 */
export function TemplateVersionSelector({
  template,
  versionId,
  onVersionIdChange,
  className,
}: TemplateVersionSelectorProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const popupState = usePopupState({ variant: 'popover', popupId: 'versionSettings' })
  const [isImportDialogOpen, setIsImportDialogOpen] = useState<boolean>(false)

  const [updateVersion] = useUpdateFormTemplateVersionMutation()
  const [deleteVersion] = useDeleteFormTemplateVersionMutation()
  const [cloneVersion] = useCloneFormTemplateVersionMutation()

  const versions = useMemo(() => {
    return _.orderBy(template.versions, (version) => version.versionNumber, 'desc')
  }, [template.versions])

  const latestVersion = useMemo(() => {
    return _.first(versions)
  }, [versions])

  const selectedVersion = useMemo(() => {
    return versions.find((version) => version.id === versionId) ?? null
  }, [versionId, versions])

  const handleDownloadFile = useCallback(() => {
    if (!selectedVersion) {
      return
    }
    const name = getFilename([template.userVisibleName, `v${selectedVersion.versionNumber}`], 'pdf')
    saveUrlAs(selectedVersion.file.url, name)
    popupState.close()
    snackbar.showSuccess('File downloaded')
  }, [popupState, selectedVersion, snackbar, template.userVisibleName])

  const onFilesSelected = useCallback(
    (plainFiles: File[]) => {
      if (!selectedVersion) {
        return
      }
      const file = plainFiles[0]
      snackbar.showLoading('Uploading version file...')
      const confirmed = window.confirm('Are you sure you want to replace this version?')
      if (!confirmed) {
        return
      }
      snackbar.showLoading('Replacing version file...')
      updateVersion({
        variables: {
          input: {
            id: selectedVersion.id,
            file,
          },
        },
      })
        .then(() => {
          snackbar.showSuccess()
          popupState.close()
        })
        .catch((err) => snackbar.showError(err.message))
    },
    [popupState, selectedVersion, snackbar, updateVersion]
  )

  const { openFilePicker } = useFilePicker({
    accept: ['.pdf', '.docx', '.xlsx'],
    readFilesContent: false,
    onFilesSelected: ({ plainFiles }) => onFilesSelected(plainFiles),
  })

  const handleExportAnnotations = useCallback(() => {
    if (!selectedVersion) {
      return
    }
    const annotations = JSON.stringify(selectedVersion.annotations)
    const blob = new Blob([annotations], { type: 'application/json' })
    const filename = getFilename(['annotations', template.userVisibleName], 'json')
    saveAs(blob, filename)
  }, [selectedVersion, template.userVisibleName])

  const handleImportAnnotations = useCallback(() => {
    setIsImportDialogOpen(true)
    popupState.close()
  }, [popupState])

  const handleCopyTemplateId = useCallback(() => {
    copy(template.id)
    snackbar.showSuccess('Copied template ID')
    popupState.close()
  }, [popupState, snackbar, template.id])

  const handleCopyVersionId = useCallback(() => {
    if (!selectedVersion) {
      return
    }
    copy(selectedVersion.id)
    snackbar.showSuccess('Copied version ID')
    popupState.close()
  }, [popupState, selectedVersion, snackbar])

  const handleClone = useCallback(() => {
    if (!selectedVersion) {
      return
    }
    popupState.close()
    if (!window.confirm('Are you sure you want to clone this version?')) {
      return
    }

    snackbar.showLoading('Cloning version...')
    cloneVersion({
      variables: { formTemplateVersionId: selectedVersion.id },
      update(cache, { data }) {
        if (!data) {
          return
        }
        const ref = cache.writeFragment({
          fragment: fragments.formTemplateVersion,
          fragmentName: 'FormTemplateVersionProperties',
          data: data.cloneFormTemplateVersion,
        })
        cache.modify<WritableDeep<FormTemplate>>({
          id: cache.identify(template),
          fields: {
            versions(existing, { toReference }) {
              const existingRefs = toReferences(existing, toReference)
              return ref ? [...existingRefs, ref] : existingRefs
            },
          },
        })
      },
    })
      .then((result) => {
        onVersionIdChange(result.data?.cloneFormTemplateVersion.id ?? '')
        snackbar.showSuccess('Cloned template version')
      })
      .catch((error) => snackbar.showError(error.message))
  }, [cloneVersion, onVersionIdChange, popupState, selectedVersion, snackbar, template])

  const handleDelete = useCallback(() => {
    if (!selectedVersion) {
      return
    }
    popupState.close()
    if (!window.confirm('Are you sure you want to delete this version?')) {
      return
    }
    snackbar.showLoading('Deleting version...')
    deleteVersion({
      variables: { id: selectedVersion.id },
      update: (cache) => {
        cache.modify<WritableDeep<FormTemplate>>({
          id: cache.identify(template),
          fields: {
            versions(existing, { toReference, readField }) {
              const existingRefs = toReferences(existing, toReference)
              const newRefs = existingRefs.filter(
                (ref) => readField('id', ref) !== selectedVersion.id
              )
              return newRefs
            },
          },
        })
      },
    })
      .then(() => {
        const previousVersion = versions.find((version) => version.id !== selectedVersion.id)
        onVersionIdChange(previousVersion?.id ?? '')
        snackbar.showSuccess('Deleted version')
      })
      .catch((error) => snackbar.showError(error.message))
  }, [deleteVersion, onVersionIdChange, popupState, selectedVersion, snackbar, template, versions])

  return (
    <div className={clsx(classes.row, className)}>
      <FormControl>
        <Select
          value={versionId}
          onChange={(ev) => onVersionIdChange(ev.target.value)}
          size="small"
          variant="outlined"
        >
          {versions.map((version) => (
            <MenuItem key={version.id} value={version.id}>
              v{version.versionNumber}
              {latestVersion?.id === version.id ? ' (latest)' : ''}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <IconButton className={classes.more} {...bindToggle(popupState)}>
        <SettingsIcon />
      </IconButton>
      <Menu {...bindMenu(popupState)}>
        <MenuItem onClick={handleDownloadFile}>
          <ListItemIcon>
            <CloudDownload fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download file</ListItemText>
        </MenuItem>
        <MenuItem onClick={openFilePicker}>
          <ListItemIcon>
            <CloudUpload fontSize="small" />
          </ListItemIcon>
          <ListItemText>Replace file</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleExportAnnotations}>
          <ListItemIcon>
            <Download fontSize="small" />
          </ListItemIcon>
          <ListItemText>Export annotations</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleImportAnnotations}>
          <ListItemIcon>
            <Upload fontSize="small" />
          </ListItemIcon>
          <ListItemText>Import annotations</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleCopyVersionId}>
          <ListItemIcon>
            <Assignment fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy version ID</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleCopyTemplateId}>
          <ListItemIcon>
            <Assignment fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy template ID</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClone}>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Clone version</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteForever fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete version</ListItemText>
        </MenuItem>
      </Menu>
      {selectedVersion && (
        <FormTemplateVersionAnnotationImport
          formTemplateVersion={selectedVersion}
          open={isImportDialogOpen}
          setOpen={setIsImportDialogOpen}
        />
      )}
    </div>
  )
}
