import { gql } from '@apollo/client'
import ClearIcon from '@mui/icons-material/Clear'
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid2,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStylesFast } from 'siteline-common-web'
import { v4 as uuidv4 } from 'uuid'
import * as fragments from '../../common/graphql/Fragments'
import { useUpdateContractUsersMutation } from '../../common/graphql/apollo-operations'
import { ContractForDetails, UserForContractDetails } from './ContractDetails'

const useStyles = makeStylesFast(() => ({
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  autocomplete: {
    width: '400px',
  },
}))

gql`
  mutation updateContractUsers($input: UpdateContractUsersInput!) {
    updateContractUsers(input: $input) {
      id
      users {
        id
        user {
          ...UserProperties
        }
      }
    }
  }
  ${fragments.user}
`

function AddUserToProject({
  users,
  onAdd,
}: {
  users: UserForContractDetails[]
  onAdd: (user: UserForContractDetails) => void
}) {
  const classes = useStyles()
  const [selectedUser, setSelectedUser] = useState<UserForContractDetails>(users[0])
  useEffect(() => {
    setSelectedUser(users[0])
  }, [users])

  return (
    <TableRow>
      <TableCell>
        <Autocomplete
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          options={users}
          includeInputInList
          onChange={(ev, value) => value && setSelectedUser(value)}
          value={selectedUser}
          multiple={false}
          renderInput={(params) => <TextField {...params} />}
          className={classes.autocomplete}
        />
      </TableCell>
      <TableCell align="right">
        <Button disabled={!selectedUser} onClick={() => onAdd(selectedUser)}>
          Add
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default function ContractDetailsUsers({ contract }: { contract: ContractForDetails }) {
  const classes = useStyles()

  const [isEditing, setIsEditing] = useState(false)
  const contractUsers = contract.users.map((contractUser) => contractUser.user)
  const [shownUsers, setShownUsers] = useState<UserForContractDetails[]>([...contractUsers])

  const [updateUsers] = useUpdateContractUsersMutation()

  const users = contract.company.users.map((companyUser) => companyUser.user)
  const notAddedUsers = users.filter(
    (user) => !contract.users.some((contractUser) => contractUser.user.id === user.id)
  )
  const [notShownUsers, setNotShownUsers] = useState<UserForContractDetails[]>([...notAddedUsers])

  const onCancel = () => {
    if (isEditing) {
      setShownUsers([...contractUsers])
      setNotShownUsers([...notAddedUsers])
    }
    setIsEditing(!isEditing)
  }

  const save = async () => {
    setIsEditing(false)

    // All users being shown that are not in the project's user list are new
    const addedUsers = _.difference(shownUsers, contractUsers)

    // All users not being shown that are not in the original not shown list are removed
    const removedUsers = _.difference(notShownUsers, notAddedUsers)

    await updateUsers({
      variables: {
        input: {
          id: contract.id,
          addUserIds: addedUsers.map((user) => user.id),
          removeUserIds: removedUsers.map((user) => user.id),
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateContractUsers: {
          ...contract,
          users: shownUsers.map((user) => ({
            __typename: 'ContractUser',
            id: uuidv4(),
            user,
          })),
        },
      },
    })
  }

  const addShownUser = (user: UserForContractDetails) => {
    const newShownUsers = [...shownUsers]
    setShownUsers([...newShownUsers, user])
    const newNotShownUsers = [...notShownUsers]
    const index = newNotShownUsers.indexOf(user)
    newNotShownUsers.splice(index, 1)
    setNotShownUsers(newNotShownUsers)
  }

  const removeShownUser = (user: UserForContractDetails) => {
    const newNotShownUsers = [...notShownUsers]
    setNotShownUsers([...newNotShownUsers, user])
    const newShownUsers = [...shownUsers]
    const index = newShownUsers.indexOf(user)
    newShownUsers.splice(index, 1)
    setShownUsers(newShownUsers)
  }

  return (
    <Grid2>
      <Card className={classes.card}>
        <CardHeader
          title="Users"
          action={
            <Button color="primary" onClick={() => onCancel()}>
              {isEditing ? 'Cancel' : 'Edit'}
            </Button>
          }
        />
        <Divider />
        <CardContent className={classes.cardContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Job title</TableCell>
                <TableCell>Phone number</TableCell>
                <TableCell>{isEditing ? 'Remove user' : ''}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEditing &&
                shownUsers.map((user) => (
                  <TableRow hover key={user.id}>
                    <TableCell>
                      <Link component={RouterLink} to={`/users/${user.id}`} underline="hover">
                        {user.firstName} {user.lastName}
                      </Link>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.jobTitle ?? ''}</TableCell>
                    <TableCell>{user.phoneNumber ?? ''}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
              {isEditing &&
                shownUsers.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>
                      {user.firstName} {user.lastName}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.jobTitle}</TableCell>
                    <TableCell>{user.phoneNumber}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => removeShownUser(user)} size="large">
                        <ClearIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {isEditing && (
            <Table>
              <TableBody>
                <>
                  {!!notShownUsers.length && (
                    <AddUserToProject users={notShownUsers} onAdd={addShownUser} />
                  )}
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell align="right">
                      <Button color="primary" variant="contained" onClick={save}>
                        Save
                      </Button>
                    </TableCell>
                  </TableRow>
                </>
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
    </Grid2>
  )
}
