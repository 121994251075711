import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { SentryLink } from 'apollo-link-sentry'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { getApp, getApps, initializeApp } from 'firebase/app'
import { User, getAuth } from 'firebase/auth'
import { createClient } from 'graphql-ws'
import _ from 'lodash'
import { config as appConfig, firebaseConfig } from './common/config/constants'
import {
  CloudLoggingLogsInput,
  GetPaginatedCompaniesInput,
  GetPaginatedContractsInput,
  GetPaginatedTemplatesInput,
  GetPaginatedWriteSyncOperationsInput,
} from './common/graphql/apollo-operations'

const wsLink = new GraphQLWsLink(
  createClient({
    url: appConfig.url.SUBSCRIPTIONS_URL,
    connectionParams: async () => ({
      authToken: await getAuthToken(),
    }),
    lazy: true,
  })
)

const uploadLink = createUploadLink({
  uri: appConfig.url.GRAPHQL_API_URL,
  headers: { 'Apollo-Require-Preflight': 'true' },
})

// Using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // Split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  uploadLink as unknown as ApolloLink
)

export function getCurrentUser(): User | null {
  const auth = getAuth(getApp())
  return auth.currentUser
}

async function getAuthToken(): Promise<string | null> {
  const currentUser = getCurrentUser()
  if (!currentUser) {
    return null
  }
  return currentUser.getIdToken()
}

export const getCurrentUserAuthorization = async () => {
  const token = await getAuthToken()
  if (!token) {
    return ''
  }
  return `Bearer ${token}`
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const authLink = setContext(async (_, { headers }: any) => {
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: await getCurrentUserAuthorization(),
    },
  }
})

const sentryLink = new SentryLink({
  setTransaction: false,
  attachBreadcrumbs: {
    includeQuery: true,
    includeVariables: true,
    includeError: true,
  },
})

// This enforces that developers think about pagination invalidation when adding new filters.
// For each field, specify whether it should invalidate the paginated list.
// Typically this is true for all filters and sort keys, false for everything related to pagination (limit, cursor).
const paginatedTemplateInvalidation: {
  [key in keyof Required<GetPaginatedTemplatesInput>]: boolean
} = {
  cursor: false,
  limit: false,
  sortCriteria: true,
  sortOrder: true,
  search: true,
  status: true,
  assigneeId: true,
  waitingOnId: true,
  relatedCompanyId: true,
  associatedContractId: true,
  tags: true,
  type: true,
  includeInactive: true,
}
const paginatedTemplateInvalidationKeys = _.keys(
  _.pickBy(paginatedTemplateInvalidation, (include) => include)
)

const paginatedContractInvalidation: {
  [key in keyof Required<GetPaginatedContractsInput>]: boolean
} = {
  cursor: false,
  limit: false,
  month: true,
  billingType: true,
  contractStatus: true,
  leadPMIds: true,
  generalContractorIds: true,
  officeIds: true,
  projectIds: true,
  templateIds: true,
  payAppStatus: true,
  complianceStatus: true,
  hasBillingForecast: true,
  search: true,
  sortCriteria: true,
  sortOrder: true,
  submitVia: true,
  isProcessingForms: true,
}
const paginatedContractInvalidationKeys = _.keys(
  _.pickBy(paginatedContractInvalidation, (include) => include)
)

const paginatedCompanyInvalidation: {
  [key in keyof Required<GetPaginatedCompaniesInput>]: boolean
} = {
  cursor: false,
  limit: false,
  sortCriteria: true,
  sortOrder: true,
  search: true,
}
const paginatedCompanyInvalidationKeys = _.keys(
  _.pickBy(paginatedCompanyInvalidation, (include) => include)
)

const paginatedOperationsInvalidation: {
  [key in keyof Required<GetPaginatedWriteSyncOperationsInput>]: boolean
} = {
  cursor: false,
  limit: false,
  search: true,
  status: true,
  sort: true,
}
const paginatedOperationsInvalidationKeys = _.keys(
  _.pickBy(paginatedOperationsInvalidation, (include) => include)
)

const paginatedLogsInvalidation: {
  [key in keyof Required<CloudLoggingLogsInput>]: boolean
} = {
  pageToken: false,
  pageSize: true,
  filter: true,
  orderBy: true,
  autoPaginate: true,
}
const paginatedLogsInvalidationKeys = _.keys(
  _.pickBy(paginatedLogsInvalidation, (include) => include)
)

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([sentryLink, authLink, link]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          // See https://www.apollographql.com/docs/react/pagination/cursor-based/#using-list-element-ids-as-cursors
          paginatedTemplates: {
            keyArgs: [
              'input',
              // If any of the following filters/sort changes, apollo will create a new paginated list instead of merging.
              paginatedTemplateInvalidationKeys,
            ],
            merge(existing, incoming, { readField }) {
              const existingTemplates = existing?.templates ?? []
              const mergedTemplates = _.uniqBy(
                [...existingTemplates, ...incoming.templates],
                (template) => readField('id', template)
              )
              return { ...incoming, templates: mergedTemplates }
            },
          },

          // See https://www.apollographql.com/docs/react/pagination/cursor-based/#using-list-element-ids-as-cursors
          paginatedContracts: {
            keyArgs: [
              'input',
              // If any of the following filters/sort changes, apollo will create a new paginated list instead of merging.
              paginatedContractInvalidationKeys,
            ],
            merge(existing, incoming, { readField }) {
              const existingContracts = existing?.contracts ?? []
              const mergedContracts = _.uniqBy(
                [...existingContracts, ...incoming.contracts],
                (contract) => readField('id', contract)
              )
              return { ...incoming, contracts: mergedContracts }
            },
          },

          // See https://www.apollographql.com/docs/react/pagination/cursor-based/#using-list-element-ids-as-cursors
          paginatedCompanies: {
            keyArgs: [
              'input',
              // If any of the following filters/sort changes, apollo will create a new paginated list instead of merging.
              paginatedCompanyInvalidationKeys,
            ],
            merge(existing, incoming, { readField }) {
              const existingCompanies = existing?.companies ?? []
              const mergedCompanies = _.uniqBy(
                [...existingCompanies, ...incoming.companies],
                (company) => readField('id', company)
              )
              return { ...incoming, companies: mergedCompanies }
            },
          },

          // See https://www.apollographql.com/docs/react/pagination/cursor-based/#using-list-element-ids-as-cursors
          paginatedWriteSyncOperations: {
            keyArgs: [
              'input',
              // If any of the following filters/sort changes, apollo will create a new paginated list instead of merging.
              paginatedOperationsInvalidationKeys,
            ],
            merge(existing, incoming, { readField }) {
              const existingOperations = existing?.operations ?? []
              const mergedOperations = _.uniqBy(
                [...existingOperations, ...incoming.operations],
                (operation) => readField('id', operation)
              )
              return { ...incoming, operations: mergedOperations }
            },
          },

          // See https://www.apollographql.com/docs/react/pagination/cursor-based/#using-list-element-ids-as-cursors
          cloudLoggingLogs: {
            keyArgs: [
              'input',
              // If any of the following filters/sort changes, apollo will create a new paginated list instead of merging.
              paginatedLogsInvalidationKeys,
            ],
            merge(existing, incoming) {
              const existingEntries = existing?.entries ?? []
              const mergedEntries = [...existingEntries, ...incoming.entries]
              return { ...incoming, entries: mergedEntries }
            },
          },
        },
      },
    },
  }),
})

/**
 * Initializes Firebase and Firebase Auth. Sets up a watcher when the user state changes.
 * @param initialized A function to call when Firebase has been initialized
 * @param userCallback A function to call when a user is logged in and Apollo is configured
 */
export function initializeFirebaseAuth(initialized: () => void, userCallback: () => void) {
  const apps = getApps()

  // Initialize Firebase
  if (apps.length === 0) {
    initializeApp(firebaseConfig)
  }

  let isFirebaseConfigured = false

  // Watch when the user changes and reset the headers for our GraphQL client with the new JWT token
  getAuth(getApp()).onAuthStateChanged((user) => {
    if (user) {
      userCallback()
    } else {
      // User is logged out, clear the cache
      apolloClient.clearStore()
    }

    if (!isFirebaseConfigured) {
      initialized()
      isFirebaseConfigured = true
    }
  })
}
