import Fuse, { FuseOptionKey, IFuseOptions } from 'fuse.js'
import { centsToDollars, formatCentsToDollars } from 'siteline-common-all'

/**
 * Strips out spaces at the start/end and replaces newlines/tabs with spaces in the string
 * @internal
 */
export function cleanString(value: string | number): string {
  const strValue = String(value)
  const strippedStrValue = strValue.replace(/[\n\t]/g, ' ').trim()
  return strippedStrValue
}

/**
 * Search the given set of entities for the given query string on the provided keys (or all keys
 * if none are provided). Uses a threshold of 0.2 to determine if a match is found.
 */
export function fuseSearch<T>(
  entities: T[],
  queryString: string | undefined,
  keys: FuseOptionKey<unknown>[] = [],
  options: IFuseOptions<T> = {}
): T[] {
  if (!queryString) {
    return entities
  }
  const threshold = options.threshold ?? 0.2
  const ignoreLocation = options.ignoreLocation ?? false

  const fuse = new Fuse(entities, { ...options, threshold, ignoreLocation, keys })
  return fuse.search(queryString).map((entry) => entry.item)
}

/**
 * Returns a formatter that handles converting numbers to their compact form
 *
 * @example
 * const formatter = compactNotationFormatter({
 *  minimumFractionDigits: 2,
 *  maximumFractionDigits: 2,
 *  currencySymbol: 'USD',
 * })
 * formatter.format(1030) // => '$1.03K'
 */

export function compactNotationFormatter({
  minimumFractionDigits,
  maximumFractionDigits,
  shouldAppendCurrencySymbol,
}: {
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  shouldAppendCurrencySymbol?: boolean
}) {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    minimumFractionDigits,
    maximumFractionDigits,
    style: shouldAppendCurrencySymbol ? 'currency' : undefined,
    currency: shouldAppendCurrencySymbol ? 'USD' : undefined,
  })
}

const ONE_BILLION = 1_000_000_000
export const ONE_MILLION = 1_000_000

const tableCellFormatter = compactNotationFormatter({
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  shouldAppendCurrencySymbol: true,
})

/**
 * Formats a currency amount (cents) with 2 decimal places and a leading "$"
 * currency symbol. This function is specific to values in tables with cell width
 * limitations. If the number is at or above the provided `maxExpandedValue` ($1B
 * by default), the number will be expressed using compact notation.
 */
export function formatTableCellNumber(value: number, maxExpandedValue: number = ONE_BILLION) {
  const dollarValue = centsToDollars(value)
  if (dollarValue >= maxExpandedValue) {
    return tableCellFormatter.format(dollarValue)
  }
  if (dollarValue <= maxExpandedValue * -1) {
    return tableCellFormatter.format(dollarValue)
  }

  return formatCentsToDollars(value, true)
}
