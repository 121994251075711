import { useCallback, useMemo, useState } from 'react'

type UseToggleResult = [
  /** Current toggle state */
  boolean,
  /** Toggle ON */
  () => void,
  /** Toggle OFF */
  () => void,
]

export function useToggle(initialState?: boolean): UseToggleResult {
  const [state, setState] = useState(initialState ?? false)

  const handleToggleOn = useCallback(() => setState(true), [])
  const handleToggleOff = useCallback(() => setState(false), [])

  return useMemo(
    () => [state, handleToggleOn, handleToggleOff],
    [handleToggleOff, handleToggleOn, state]
  )
}
