export function getXAxisProps(isSmallScreen: boolean) {
  if (isSmallScreen) {
    return { tickAngle: 45, labelLength: 15, xAxisHeight: 100, labelAnchor: 'start' }
  }
  return { tickAngle: 0, labelLength: 20, xAxisHeight: 40, labelAnchor: 'middle' }
}

export function getSmallChartXAxisProps(isSmallScreen: boolean) {
  if (isSmallScreen) {
    return { tickAngle: 45, labelLength: 10, xAxisHeight: 75, labelAnchor: 'start' }
  }
  return { tickAngle: 0, labelLength: 15, xAxisHeight: 40, labelAnchor: 'middle' }
}
